<template>
  <div>
    <el-tabs v-model="activeTabName" type="card">
      <el-tab-pane name="notifyListTab" :label="$t('commons.notifyList')">
        <div class="filter-container">
          <el-date-picker
            class="filter-item"
            v-model="pickerOpts.pickerData"
            :picker-options="pickerOpts"
            type="datetimerange"
            @change="setDateRange"
            :range-separator="$t('commons.rangeSeparator')"
            :start-placeholder="$t('commons.startDate')"
            :end-placeholder="$t('commons.endDate')"
          >
          </el-date-picker>
          <el-select
            v-model="notifyListTab.listQuery.readStatus"
            clearable
            @change="getNotifyDetailPage"
            :placeholder="$t('notify.manager.readStatus')"
            style="width: 120px"
            class="filter-item"
          >
            <el-option
              :value="1"
              :label="$t('notify.manager.unread')"
            ></el-option>
            <el-option
              :value="2"
              :label="$t('notify.manager.read')"
            ></el-option>
          </el-select>
          <el-select
            v-model="notifyListTab.listQuery.notifyType"
            clearable
            @change="getNotifyDetailPage"
            :placeholder="$t('notify.manager.objectType')"
            style="width: 120px"
            class="filter-item"
          >
            <el-option
              :value="1"
              :label="$t('notify.manager.platformPrivateLetters')"
            ></el-option>
            <el-option
              :value="2"
              :label="$t('notify.manager.platformNotice')"
            ></el-option>
            <el-option
              :value="3"
              :label="$t('notify.manager.sysPrivateLetters')"
            ></el-option>
            <el-option
              :value="4"
              :label="$t('notify.manager.sysNotice')"
            ></el-option>
          </el-select>
          <el-input
            :placeholder="$t('commons.searchPhrase')"
            v-model="notifyListTab.listQuery.searchPhrase"
            clearable
            maxlength="50"
            style="width: 165px"
            class="filter-item"
            @keyup.enter.native="getNotifyDetailPage"
          />
          <template v-if="currentTenant == -1">
            <tenant-select
              style="width: 165px"
              size="small"
              class="filter-item"
              :tenantLimit="true"
              v-model="notifyListTab.listQuery.tenantId"
              @change="getNotifyDetailPage"
            ></tenant-select>
          </template>
          <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-search"
            :loading="notifyListTab.listLoading"
            @click="getNotifyDetailPage"
          >
            {{ $t("commons.search") }}
          </el-button>
        </div>
        <div class="filter-container">
          <el-button
            :disabled="notifyListTab.multipleSelection.length === 0"
            icon="el-icon-delete"
            type="danger"
            size="small"
            plain
            @click="batchDelete"
          >
            {{ $t("commons.batchDelete") }}
          </el-button>
          <el-button
            :disabled="notifyListTab.list.length === 0"
            icon="el-icon-delete-solid"
            type="danger"
            size="small"
            plain
            @click="clearALL"
          >
            {{ $t("commons.clearAll") }}
          </el-button>
        </div>
        <el-table
          v-loading="notifyListTab.listLoading"
          :data="notifyListTab.list"
          border
          fit
          stripe
          highlight-current-row
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            prop="notifyRecordId"
            fixed
            type="selection"
            align="center"
            width="50"
          >
          </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-row>
                  <el-col :span="12">
                    <el-form-item :label="$t('notify.manager.notifyTime')">
                      <i class="el-icon-time" />
                      <span>{{
                        scope.row.notifyTime | dateFormat("YYYY-MM-DD HH:mm:ss")
                      }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('notify.manager.readTime')">
                      <template v-if="scope.row.readTime != null">
                        <i class="el-icon-time" />
                      </template>
                      <span>{{
                        scope.row.readTime | dateFormat("YYYY-MM-DD HH:mm:ss")
                      }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="$t('notify.manager.title')">
                      <span>{{ scope.row.notifyTitle }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="tenantName" :label="$t('tenant.tenantName')">
            <template slot-scope="scope">
              <span>{{ scope.row.tenantName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="notifyTitle"
            :label="$t('notify.manager.title')"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.notifyTitle }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="receiverName"
            :label="$t('notify.manager.receiver')"
            width="80"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.receiverName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="senderName"
            :label="$t('notify.manager.sender')"
            width="80"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.senderName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="readStatus"
            :label="$t('commons.status')"
            width="80"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.readStatus == 1">
                <span>{{ $t("notify.manager.unread") }}</span>
              </template>
              <template v-if="scope.row.readStatus == 2">
                <span>{{ $t("notify.manager.read") }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="notifyType"
            :label="$t('commons.type')"
            width="100"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.notifyType == 1">
                <span>{{ $t("notify.manager.platformPrivateLetters") }}</span>
              </template>
              <template v-if="scope.row.notifyType == 2">
                <span>{{ $t("notify.manager.platformNotice") }}</span>
              </template>
              <template v-if="scope.row.notifyType == 3">
                <span>{{ $t("notify.manager.sysPrivateLetters") }}</span>
              </template>
              <template v-if="scope.row.notifyType == 4">
                <span>{{ $t("notify.manager.sysNotice") }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="notifyChannel"
            :label="$t('notify.manager.channel')"
            width="120"
          >
            <template slot-scope="scope">
              <template v-for="channel in scope.row.notifyChannel.split(',')">
                <template v-if="channel == '1'"> Web&nbsp; </template>
                <template v-if="channel == '2'"> APP&nbsp; </template>
              </template>
            </template>
          </el-table-column>

          <el-table-column
            prop="sendTime"
            :label="$t('notify.manager.sendTime')"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="100"
            :label="$t('commons.actions')"
          >
            <template slot-scope="s">
              <el-tooltip :content="$t('commons.delete')">
                <el-button
                  @click="deleteNotify(s.row)"
                  circle
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                >
                </el-button>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-tooltip
                :content="$t('notify.manager.findContent')"
                placement="bottom"
                :open-delay="openDelay"
              >
                <el-button
                  size="mini"
                  icon="el-icon-view"
                  circle
                  @click="findContent(scope.row)"
                >
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="notifyListTab.total > 0"
          :total="notifyListTab.total"
          :page.sync="notifyListTab.listQuery.current"
          :limit.sync="notifyListTab.listQuery.rowCount"
          @pagination="getNotifyDetailPage"
        />
      </el-tab-pane>
      <el-tab-pane name="recycleBinTab" :label="$t('commons.recycleBin')">
        <div class="filter-container">
          <el-date-picker
            class="filter-item"
            v-model="pickerOpts.pickerData"
            :picker-options="pickerOpts"
            type="datetimerange"
            @change="setDateRange"
            :range-separator="$t('commons.rangeSeparator')"
            :start-placeholder="$t('commons.startDate')"
            :end-placeholder="$t('commons.endDate')"
          >
          </el-date-picker>
          <el-select
            v-model="recycleBinTab.listQuery.readStatus"
            clearable
            @change="getRecycleBinPage"
            :placeholder="$t('notify.manager.readStatus')"
            style="width: 120px"
            class="filter-item"
          >
            <el-option
              :value="1"
              :label="$t('notify.manager.unread')"
            ></el-option>
            <el-option
              :value="2"
              :label="$t('notify.manager.read')"
            ></el-option>
          </el-select>
          <el-select
            v-model="notifyListTab.listQuery.notifyType"
            clearable
            @change="getNotifyDetailPage"
            :placeholder="$t('notify.manager.objectType')"
            style="width: 120px"
            class="filter-item"
          >
            <el-option
              :value="1"
              :label="$t('notify.manager.platformPrivateLetters')"
            ></el-option>
            <el-option
              :value="2"
              :label="$t('notify.manager.platformNotice')"
            ></el-option>
            <el-option
              :value="3"
              :label="$t('notify.manager.sysPrivateLetters')"
            ></el-option>
            <el-option
              :value="4"
              :label="$t('notify.manager.sysNotice')"
            ></el-option>
          </el-select>
          <el-input
            :placeholder="$t('commons.searchPhrase')"
            v-model="recycleBinTab.listQuery.searchPhrase"
            clearable
            maxlength="50"
            style="width: 165px"
            class="filter-item"
            @keyup.enter.native="getRecycleBinPage"
          />
          <tenant-select
            style="width: 165px"
            size="small"
            class="filter-item"
            :tenantLimit="true"
            v-model="notifyListTab.listQuery.tenantId"
            @change="getNotifyDetailPage"
          ></tenant-select>
          <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-search"
            :loading="recycleBinTab.listLoading"
            @click="getRecycleBinPage"
          >
            {{ $t("commons.search") }}
          </el-button>
        </div>
        <div class="filter-container">
          <el-button
            :disabled="recycleBinTab.multipleSelection.length === 0"
            icon="el-icon-refresh-left"
            type="success"
            size="small"
            plain
            @click="batchRestore"
          >
            {{ $t("commons.batchRestore") }}
          </el-button>
          <el-button
            :disabled="recycleBinTab.multipleSelection.length === 0"
            icon="el-icon-delete"
            type="danger"
            size="small"
            plain
            @click="batchDestroy"
          >
            {{ $t("commons.batchDestroy") }}
          </el-button>
          <el-button
            :disabled="recycleBinTab.list.length === 0"
            icon="el-icon-delete-solid"
            type="danger"
            size="small"
            plain
            @click="destroyALL"
          >
            {{ $t("commons.clearAll") }}
          </el-button>
        </div>
        <el-table
          v-loading="recycleBinTab.listLoading"
          :data="recycleBinTab.list"
          border
          fit
          stripe
          highlight-current-row
          @selection-change="handleSelectionChange4R"
        >
          <el-table-column
            prop="notifyRecordId"
            fixed
            type="selection"
            align="center"
            width="50"
          >
          </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-row>
                  <el-col :span="12">
                    <el-form-item :label="$t('notify.manager.notifyTime')">
                      <i class="el-icon-time" />
                      <span>{{
                        scope.row.notifyTime | dateFormat("YYYY-MM-DD HH:mm:ss")
                      }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('notify.manager.readTime')">
                      <template v-if="scope.row.readTime != null">
                        <i class="el-icon-time" />
                      </template>
                      <span>{{
                        scope.row.readTime | dateFormat("YYYY-MM-DD HH:mm:ss")
                      }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="$t('notify.manager.title')">
                      <span>{{ scope.row.notifyTitle }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="tenantName" :label="$t('tenant.tenantName')">
            <template slot-scope="scope">
              <span>{{ scope.row.tenantName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="notifyTitle"
            :label="$t('notify.manager.title')"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.notifyTitle }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="receiverName"
            :label="$t('notify.manager.receiver')"
            width="80"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.receiverName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="senderName"
            :label="$t('notify.manager.sender')"
            width="80"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.senderName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="readStatus"
            :label="$t('commons.status')"
            width="100"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.readStatus == 1">
                <span>{{ $t("notify.manager.unread") }}</span>
              </template>
              <template v-if="scope.row.readStatus == 2">
                <span>{{ $t("notify.manager.read") }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="notifyType"
            :label="$t('commons.type')"
            width="100"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.notifyType == 1">
                <span>{{ $t("notify.manager.platformPrivateLetters") }}</span>
              </template>
              <template v-if="scope.row.notifyType == 2">
                <span>{{ $t("notify.manager.platformNotice") }}</span>
              </template>
              <template v-if="scope.row.notifyType == 3">
                <span>{{ $t("notify.manager.sysPrivateLetters") }}</span>
              </template>
              <template v-if="scope.row.notifyType == 4">
                <span>{{ $t("notify.manager.sysNotice") }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="notifyChannel"
            :label="$t('notify.manager.channel')"
            width="120"
          >
            <template slot-scope="scope">
              <template v-for="channel in scope.row.notifyChannel.split(',')">
                <template v-if="channel == '1'"> Web&nbsp; </template>
                <template v-if="channel == '2'"> APP&nbsp; </template>
              </template>
            </template>
          </el-table-column>

          <el-table-column
            prop="sendTime"
            :label="$t('notify.manager.sendTime')"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="150"
            :label="$t('commons.actions')"
          >
            <template slot-scope="s">
              <el-tooltip
                :content="$t('notify.manager.findContent')"
                placement="bottom"
                :open-delay="openDelay"
              >
                <el-button
                  size="mini"
                  icon="el-icon-view"
                  circle
                  @click="findContent(s.row)"
                >
                </el-button>
              </el-tooltip>
              <el-tooltip :content="$t('commons.restore')">
                <el-button
                  @click="restoreNotify(s.row)"
                  circle
                  type="success"
                  icon="el-icon-refresh-left"
                  size="mini"
                >
                </el-button>
              </el-tooltip>
              <el-tooltip :content="$t('commons.destroy')">
                <el-button
                  @click="destroyNotify(s.row)"
                  circle
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                >
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="recycleBinTab.total > 0"
          :total="recycleBinTab.total"
          :page.sync="recycleBinTab.listQuery.current"
          :limit.sync="recycleBinTab.listQuery.rowCount"
          @pagination="getRecycleBinPage"
        />
      </el-tab-pane>
    </el-tabs>

    <information-details
      :detailDrawer.sync="detailDrawer"
      :informationInfo="contentForm"
    />
  </div>
</template>

<script>
import {
  getNotifyDetailPage,
  batchDeleteRecord,
  logicDeleteRecord,
  destroyRecord,
  batchDestroyRecord,
  clearAllRecord,
  destroyAllRecord,
  restoreRecord,
  batchRestoreRecord,
} from "@/api/ruge/notify/notify";
import Pagination from "@/components/Pagination";
import InformationDetails from "@/components/Information/components/informationDetails";
import TenantSelect from "@/components/TenantSelect";

// 声明tab名称
const tabName = {
  // 消息列表Tab
  notifyListTab: "notifyListTab",
  // 回收站Tab
  recycleBinTab: "recycleBinTab",
};

export default {
  name: "MyNotifyList",
  components: { Pagination, InformationDetails, TenantSelect },
  computed: {
    currentTenant() {
      return this.$store.getters.currentTenant;
    },
  },
  data() {
    let opt = {
      openDelay: 1000,
      detailDrawer: false,
      contentForm: null,
      // 当前tab名称
      activeTabName: tabName.notifyListTab,
      // 消息列表相关属性
      notifyListTab: {
        total: 0,
        list: [],
        listLoading: false,
        listQuery: {
          //分页参数
          current: 1,
          rowCount: 10,
          readStatus: null,
          notifyType: null,
          startTime: null,
          endTime: null,
          searchPhrase: null,
          isDelete: "N",
          userAccount: this.$store.getters.userAccount,
          tenantId: null,
        },
        multipleSelection: [],
      },
      // 回收站相关属性
      recycleBinTab: {
        total: 0,
        list: [],
        listLoading: false,
        listQuery: {
          //分页参数
          current: 1,
          rowCount: 10,
          readStatus: null,
          startTime: null,
          endTime: null,
          searchPhrase: null,
          isDelete: "Y",
          userAccount: this.$store.getters.userAccount,
        },
        multipleSelection: [],
      },
      // 日期范围查询组件
      pickerOpts: {
        // 默认日期
        pickerData: null,
        // 快捷选项
        shortcuts: null,
      },
    };
    // 设置Picker快捷选项
    this.setPickerShortcuts(opt);
    return opt;
  },
  created() {
    this.getNotifyDetailPage();
    this.getRecycleBinPage();
  },
  methods: {
    // 查询消息列表
    getNotifyDetailPage() {
      this.notifyListTab.listLoading = true;
      getNotifyDetailPage(this.notifyListTab.listQuery)
        .then((res) => {
          this.notifyListTab.list = res.rows;
          this.notifyListTab.total = res.total;
          this.notifyListTab.listLoading = false;
        })
        .catch((error) => {
          this.notifyListTab.listLoading = false;
          console.log(`查询失败，原因=> ${error}`);
        });
    },
    // 查询回收站
    getRecycleBinPage() {
      this.recycleBinTab.listLoading = true;
      getNotifyDetailPage(this.recycleBinTab.listQuery)
        .then((res) => {
          this.recycleBinTab.list = res.rows;
          this.recycleBinTab.total = res.total;
          this.recycleBinTab.listLoading = false;
        })
        .catch((error) => {
          this.recycleBinTab.listLoading = false;
          console.log(`查询失败，原因=> ${error}`);
        });
    },
    // 删除到回收站
    deleteNotify(item) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          logicDeleteRecord({ notifyRecordId: item.notifyRecordId })
            .then(() => {
              //成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              //删除成功后重新渲染table
              this.getNotifyDetailPage();
              this.getRecycleBinPage();
            })
            .catch((error) => {
              console.log(`删除失败，原因 => ${error}`);
            });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    //彻底删除
    destroyNotify(item) {
      this.$confirm(
        this.$t("message.destroyConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          destroyRecord({ notifyRecordId: item.notifyRecordId })
            .then(() => {
              //成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              //删除成功后重新渲染table
              this.getRecycleBinPage();
            })
            .catch((error) => {
              console.log(`删除失败，原因 => ${error}`);
            });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 还原
    restoreNotify(item) {
      restoreRecord({ notifyRecordId: item.notifyRecordId }).then(() => {
        //成功提示
        this.$message({
          message: this.$t("message.operationSuccess"),
          type: "success",
        });
        //删除成功后重新渲染table
        this.getNotifyDetailPage();
        this.getRecycleBinPage();
      });
    },
    // 设置日期范围查询条件
    setDateRange(dateRange) {
      if (dateRange) {
        this.notifyListTab.listQuery.startTime = dateRange[0].getTime();
        this.notifyListTab.listQuery.endTime = dateRange[1].getTime();
      } else {
        // 清空
        this.notifyListTab.listQuery.startTime = null;
        this.notifyListTab.listQuery.endTime = null;
      }
    },
    // 设置Picker快捷选项
    setPickerShortcuts(opt = this) {
      opt.pickerOpts.shortcuts = [
        {
          text: this.$t("commons.lastDay"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: this.$t("commons.lastWeek"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: this.$t("commons.lastMonth"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
      ];
    },
    handleSelectionChange(data) {
      let notifyRecordIds = [];
      data.forEach(function (obj) {
        notifyRecordIds.push(obj.notifyRecordId);
      });
      this.notifyListTab.multipleSelection = notifyRecordIds;
    },
    handleSelectionChange4R(data) {
      let notifyRecordIds = [];
      data.forEach(function (obj) {
        notifyRecordIds.push(obj.notifyRecordId);
      });
      this.recycleBinTab.multipleSelection = notifyRecordIds;
    },
    // 批量删除到回收站
    batchDelete() {
      if (this.notifyListTab.multipleSelection.length < 1) {
        this.$message({
          type: "info",
          message: this.$t("commons.multipleSelectionOne"),
        });
      } else {
        this.$confirm(
          this.$t("message.deleteConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            batchDeleteRecord({
              notifyRecordIds: this.notifyListTab.multipleSelection.join(","),
            }).then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              this.getNotifyDetailPage();
              this.getRecycleBinPage();
            });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      }
    },
    // 批量彻底删除
    batchDestroy() {
      if (this.recycleBinTab.multipleSelection.length < 1) {
        this.$message({
          type: "info",
          message: this.$t("commons.multipleSelectionOne"),
        });
      } else {
        this.$confirm(
          this.$t("message.destroyConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            batchDestroyRecord({
              notifyRecordIds: this.recycleBinTab.multipleSelection.join(","),
            }).then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              this.getRecycleBinPage();
            });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      }
    },
    // 批量还原
    batchRestore() {
      batchRestoreRecord({
        notifyRecordIds: this.recycleBinTab.multipleSelection,
      }).then(() => {
        // 成功提示
        this.$message({
          message: this.$t("message.operationSuccess"),
          type: "success",
        });
        this.getNotifyDetailPage();
        this.getRecycleBinPage();
      });
    },
    // 清空消息
    clearALL() {
      this.$confirm(
        this.$t("message.deleteAllConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          clearAllRecord().then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.getNotifyDetailPage();
            this.getRecycleBinPage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 清空回收站
    destroyALL() {
      this.$confirm(
        this.$t("message.destroyAllConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          destroyAllRecord().then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.getRecycleBinPage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    findContent(row) {
      this.contentForm = row;
      this.detailDrawer = true;
    },
  },
};
</script>

