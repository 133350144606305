<template>
  <div class="app-container personal-common-component">
    <el-tabs v-model="activeName">
      <!-- 个性设置 -->
      <el-tab-pane :label="$t('personal.setting10')" name="setting10">
        <Personalization />
      </el-tab-pane>
      <!-- 我的消息 -->
      <el-tab-pane :label="$t('personal.setting9')" name="setting9">
        <MyNotifyList />
      </el-tab-pane>
      <!-- 导出列表 -->
      <el-tab-pane :label="$t('personal.setting7')" name="setting7">
        <ExportList />
      </el-tab-pane>
      <!-- 导入列表 -->
      <el-tab-pane :label="$t('personal.setting8')" name="setting8">
        <ImportList />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ExportList from "@/views/ruge/my/export/components/exportList.vue";
import ImportList from "@/views/ruge/my/import/components/importList.vue";
import MyNotifyList from "@/views/ruge/notify/components/myNotifyList.vue";
import Personalization from "@/views/ruge/sso/personal/personalization.vue";
export default {
  name: "personalCommonComponent",
  components: {
    ExportList,
    ImportList,
    MyNotifyList,
    Personalization,
  },
  data() {
    return {
      activeName: "setting10",
    };
  },
  mounted() {
    this.forwardPage();
  },
  methods: {
    // 打开指定页面
    forwardPage() {
      let id = this.$route.query.id;
      if (id) {
        this.activeName = `setting${id}`;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.personal-common-component {
}
</style>