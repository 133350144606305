import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'
import { downExcel } from '@/utils/download'

/**
 * 获取导出记录
 * @param {Object} params
 */
export function getExportList(params) {
  return request({
    url: envInfo.bgApp.archivePath + '/excel/export/list',
    method: 'get',
    params
  })
}

/**
 * 删除导出记录
 * @param {String} taskId
 */
export function delExportRecord(params) {
    return request({
        url: envInfo.bgApp.archivePath + '/excel/export/delete/byid/'+params.taskId,
        method: 'get',
      })
}

/**
 * 根据任务ID下载导出文件
 * @param {任务ID} taskId
 */
export function downExportExcel(taskId, rootPath) {
  downExcel(taskId, "EXP", rootPath);
}

/**
 * 查询应用列表
 * @param params
 */
export function findAppList(params) {
    return request({
        url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
        method: 'get',
    })
}
