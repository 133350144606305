var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container personal-common-component" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: { label: _vm.$t("personal.setting10"), name: "setting10" },
            },
            [_c("Personalization")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("personal.setting9"), name: "setting9" } },
            [_c("MyNotifyList")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("personal.setting7"), name: "setting7" } },
            [_c("ExportList")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("personal.setting8"), name: "setting8" } },
            [_c("ImportList")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }