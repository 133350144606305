<template>
  <div class="personalization-comp">
    <div class="title-line">
      {{ $t("personal.displaySet") }}
    </div>
    <el-form label-position="top" ref="personalForm" label-width="100px">
      <el-form-item prop="layout">
        <div class="radio-group-container">
          <div
            class="single-container"
            :class="layout === 1 ? 'active-item' : ''"
            @click="layout = 1"
          >
            <img
              src="../../../../assets/images/Group.svg"
              alt=""
              class="display-image"
            />
            <div class="radio-line">
              <span class="radio-span"> </span>
              <span>{{ $t("commons.displayLayout_1") }}</span>
            </div>
          </div>
          <div
            class="single-container"
            :class="layout === 3 ? 'active-item' : ''"
            @click="layout = 3"
          >
            <img
              style="height: 150px; width: 150px"
              src="../../../../assets/images/Group 3.svg"
              alt=""
              class="display-image"
            />
            <div class="radio-line">
              <span class="radio-span"> </span>
              <span>{{ $t("commons.displayLayout_3") }}</span>
            </div>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" @click="savePersonalization">{{
          $t("commons.save")
        }}</el-button>
      </el-form-item> -->
    </el-form>
    <div class="title-line">
      {{ $t("personal.themeSet") }}
    </div>
    <el-form label-position="top" ref="personalForm" label-width="100px">
      <el-form-item prop="layout">
        <div class="radio-group-container">
          <div
            class="single-container"
            :class="theme === 1 ? 'active-item' : ''"
            @click="theme = 1"
          >
            <img
              src="../../../../assets/images/white_example.png"
              alt=""
              class="display-image"
            />
            <div class="radio-line">
              <span class="radio-span"> </span>
              <span>{{ $t("commons.theme_1") }}</span>
            </div>
          </div>
          <div
            class="single-container"
            :class="theme === 2 ? 'active-item' : ''"
            @click="theme = 2"
          >
            <img
              style="height: 150px; width: 150px"
              src="../../../../assets/images/blue_example.png"
              alt=""
              class="display-image"
            />
            <div class="radio-line">
              <span class="radio-span"> </span>
              <span>{{ $t("commons.theme_2") }}</span>
            </div>
          </div>
          <div
            class="single-container"
            :class="theme === 3 ? 'active-item' : ''"
            @click="theme = 3"
          >
            <img
              style="height: 150px; width: 150px"
              src="../../../../assets/images/black_example.png"
              alt=""
              class="display-image"
            />
            <div class="radio-line">
              <span class="radio-span"> </span>
              <span>{{ $t("commons.theme_3") }}</span>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="savePersonalization">{{
          $t("commons.save")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      layout: 1,
      theme: 1,
    };
  },
  created() {
    this.layout = this.$store.getters.displayLayout;
    this.theme = this.$store.getters.theme;
  },
  methods: {
    // 保存个性化设置
    savePersonalization() {
      if (this.layout === 2) {
        this.$message.info("开发中...");
        return;
      }
      this.$store.dispatch("setDisplayLayout", this.layout);
      this.$store.dispatch("setDisplayTheme", this.theme);
      this.$message({
        message: this.$t("message.saveSuccess"),
        type: "success",
      });
    },
  },
};
</script>
<style scoped lang="less">
.personalization-comp {
  padding-top: 40px;
  padding-bottom: 40px;
  .title-line {
    margin-bottom: 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
  }
  .radio-group-container {
    display: flex;
    .active-item {
      border: 1px solid #409eff !important;
      .radio-span {
        background-color: #409eff;
        border: none !important;
        border-radius: 50%;
        display: inline-block;
        height: 16px;
        width: 16px;
        margin-right: 10px;
        position: relative;
        &::after {
          border-radius: 50%;
          width: 6px;
          height: 6px;
          background-color: #fff;
          content: "";
          position: absolute;
          display: inline-block;
          left: calc(50% - 3px);
          top: calc(50% - 3px);
        }
      }
    }
    .single-container {
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 10px 30px 10px 10px;
      .display-image {
        height: 150px;
        width: 150px;
        margin-left: 19px;
      }
      .radio-line {
        display: flex;
        align-items: center;
        .radio-span {
          border: 1px solid #ccc;
          border-radius: 50%;
          display: inline-block;
          height: 16px;
          width: 16px;
          margin-right: 10px;
          position: relative;
        }
      }
    }
    .single-container + .single-container {
      margin-left: 20px;
    }
  }
}
</style>