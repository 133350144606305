var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personalization-comp" },
    [
      _c("div", { staticClass: "title-line" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("personal.displaySet")) + "\n  "),
      ]),
      _c(
        "el-form",
        {
          ref: "personalForm",
          attrs: { "label-position": "top", "label-width": "100px" },
        },
        [
          _c("el-form-item", { attrs: { prop: "layout" } }, [
            _c("div", { staticClass: "radio-group-container" }, [
              _c(
                "div",
                {
                  staticClass: "single-container",
                  class: _vm.layout === 1 ? "active-item" : "",
                  on: {
                    click: function ($event) {
                      _vm.layout = 1
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "display-image",
                    attrs: {
                      src: require("../../../../assets/images/Group.svg"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "radio-line" }, [
                    _c("span", { staticClass: "radio-span" }),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("commons.displayLayout_1"))),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "single-container",
                  class: _vm.layout === 3 ? "active-item" : "",
                  on: {
                    click: function ($event) {
                      _vm.layout = 3
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "display-image",
                    staticStyle: { height: "150px", width: "150px" },
                    attrs: {
                      src: require("../../../../assets/images/Group 3.svg"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "radio-line" }, [
                    _c("span", { staticClass: "radio-span" }),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("commons.displayLayout_3"))),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "title-line" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("personal.themeSet")) + "\n  "),
      ]),
      _c(
        "el-form",
        {
          ref: "personalForm",
          attrs: { "label-position": "top", "label-width": "100px" },
        },
        [
          _c("el-form-item", { attrs: { prop: "layout" } }, [
            _c("div", { staticClass: "radio-group-container" }, [
              _c(
                "div",
                {
                  staticClass: "single-container",
                  class: _vm.theme === 1 ? "active-item" : "",
                  on: {
                    click: function ($event) {
                      _vm.theme = 1
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "display-image",
                    attrs: {
                      src: require("../../../../assets/images/white_example.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "radio-line" }, [
                    _c("span", { staticClass: "radio-span" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("commons.theme_1")))]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "single-container",
                  class: _vm.theme === 2 ? "active-item" : "",
                  on: {
                    click: function ($event) {
                      _vm.theme = 2
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "display-image",
                    staticStyle: { height: "150px", width: "150px" },
                    attrs: {
                      src: require("../../../../assets/images/blue_example.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "radio-line" }, [
                    _c("span", { staticClass: "radio-span" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("commons.theme_2")))]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "single-container",
                  class: _vm.theme === 3 ? "active-item" : "",
                  on: {
                    click: function ($event) {
                      _vm.theme = 3
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "display-image",
                    staticStyle: { height: "150px", width: "150px" },
                    attrs: {
                      src: require("../../../../assets/images/black_example.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "radio-line" }, [
                    _c("span", { staticClass: "radio-span" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("commons.theme_3")))]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.savePersonalization },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }