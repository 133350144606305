import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'
import { downExcel } from '@/utils/download'

/**
 * 获取导入记录
 * @param {Object} params
 */
export function getImportList(params) {
  return request({
    url: envInfo.bgApp.archivePath + '/excel/import/list',
    method: 'get',
    params
  })
}

/**
 * 删除导入记录
 * @param {string | number} taskId
 */
export function delImportRecord(params) {
    return request({
        url: envInfo.bgApp.archivePath + '/excel/import/delete/byid/'+params.taskId,
        method: 'get',
      })
}

/**
 * 根据任务ID下载导入文件
 * @param {string | number} taskId
 * @param {string} rootPath
 */
export function downImportExcel(taskId, rootPath) {
    downExcel(taskId, "IMP", rootPath);
}

/**
 * 根据任务ID下载错误提示文件
 * @param {string | number} taskId
 * @param {string} rootPath
 */
export function downErrorExcel(taskId, rootPath) {
    downExcel(taskId, "ERR", rootPath);
}

/**
 * 查询应用列表
 * @param params
 */
export function findAppList(params) {
    return request({
        url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
        method: 'get',
    })
}
