<template>
  <div>
    <div class="filter-container">
      <el-select
        @change="getExportList"
        v-model="listQuery.sourceApp"
        @clear="clearEmpty('sourceApp')"
        filterable
        clearable
        :placeholder="$t('commons.appName')"
      >
        <el-option
          v-for="item in appList"
          :key="item.itemCode"
          :value="item.itemCode"
          :label="item.itemName"
        >
        </el-option>
      </el-select>
      <el-input
        :placeholder="$t('commons.searchPhrase')"
        v-model="listQuery.searchPhrase"
        clearable
        maxlength="50"
        style="width: 200px"
        class="filter-item"
        @keyup.enter.native="getExportList"
      />
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        :loading="loading"
        @click="getExportList"
      >
        {{ $t("commons.search") }}
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="exportList"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      stripe
    >
      <el-table-column align="center" :label="$t('commons.fileName')">
        <template slot-scope="scope">
          <el-button type="text" @click="downExportFile(scope.row)">{{
            scope.row.displayName
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('commons.type')" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.moduleName }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('commons.status')" width="100">
        <template slot-scope="scope">
          <span>
            <template v-if="scope.row.exportFlag == 0">
              {{ $t("commons.waiting") }}
            </template>
            <template v-if="scope.row.exportFlag == 1">
              {{ $t("commons.exporting") }}
            </template>
            <template v-if="scope.row.exportFlag == 2">
              {{ $t("commons.completed") }}
            </template>
            <template v-if="scope.row.exportFlag == 3">
              {{ $t("clocky.jobTraceStatus0") }}
            </template>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('export.totalCount')"
        width="100"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.totalCount }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('commons.appName')"
        width="120"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.sourceApp }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('commons.startTime')"
        width="160"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.runStartTime | dateFormat("YYYY-MM-DD HH:mm:ss")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('commons.endTime')"
        width="160"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.runEndTime | dateFormat("YYYY-MM-DD HH:mm:ss")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('commons.actions')" width="80">
        <template slot-scope="scope">
          <el-tooltip :content="$t('commons.delete')">
            <el-button
              @click="deleteExportRecord(scope.row)"
              circle
              type="danger"
              icon="el-icon-delete"
              size="mini"
            >
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.current"
      :limit.sync="listQuery.rowCount"
      @pagination="getExportList"
    />
  </div>
</template>

<script>
let manager = require("@/api/ruge/my/export.js");
import Pagination from "@/components/Pagination";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "ExportList",
  components: { Pagination },
  data() {
    return {
      appList: [],
      exportList: null,
      loading: false,
      total: 0,
      listQuery: {
        current: 1,
        rowCount: 10,
        sourceApp: null,
        searchPhrase: undefined,
      },
    };
  },
  created() {
    this.getExportList();
    this.getAppList();
  },
  methods: {
    getAppList() {
      manager.findAppList({ classifyCode: "APP_LIST" }).then((r) => {
        this.appList = r;
      });
    },
    getExportList() {
      this.loading = true;
      manager
        .getExportList(this.listQuery)
        .then((response) => {
          this.exportList = response.rows;
          this.total = response.total;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    deleteExportRecord(row) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      ).then(() => {
        manager
          .delExportRecord(row)
          .then(() => {
            this.$message({
              type: "success",
              message: this.$t("message.deleteSuccess"),
            });
            this.getExportList();
          })
          .catch(() => {});
      });
    },
    downExportFile(row) {
      // let url = envInfo.bgApp.ssoPath;//只要这些节点端口是通的，任意一个有excel下载服务的就可以
      // manager.downExportExcel(row.taskId, url)
      const map = new Map(Object.entries(envInfo.bgSuffixPath));
      manager.downExportExcel(row.taskId, map.get(row.sourceApp));
    },
    clearEmpty(field) {
      this.listQuery[field] = null;
    },
  },
};
</script>
